// import axios from "axios"
import axios from "../../../../constants/axios"
import endpoints from "../../../../constants/endpoints"

export const STATISTICS_FETCH = async (
  setStats,
  setLoading,
  username,
  dispatch
) => {
  try {
    const response = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.COMPANY_STATS + username + "/"
    )
    setStats(response.data)
    setLoading(false)
  } catch (err) {
    if (err?.response?.status === 403) {
      dispatch({ type: "LOGOUT" })
    }
    setLoading(false)
  }
}

export const CANDIDATE_SHORTLIST = async (
  company_id,
  candidate_id,
  setProcessing,
  setShortlist,
  setShortlistItems,
  shortlist,
  state,
  dispatch,
  shortlistData
) => {
  try {
    const data = shortlistData?.data
      ? shortlistData?.data
      : {
          company_id,
          candidate_id,
        }
    const res = await axios.post(
      process.env.GATSBY_BASE_URL +
        (shortlistData?.url
          ? shortlistData?.url
          : endpoints.CANDIDATE_SHORTLIST),
      data
    )
    if (res.status === 201) {
      if (shortlist && setShortlistItems) {
        setShortlistItems([...shortlist, { ...res.data }])
      }
      setShortlist(true)
    }
    setProcessing(false)
    dispatch({
      type: "COMPANY_COUNTS",
      payload: {
        ...state.company_counts,
        shortlist: state.company_counts.shortlist + 1,
      },
    })
    dispatch({
      type: "SHORTLIST_DATA",
      payload: [...state?.shortlist, shortlistData?.shortlistedData],
    })
  } catch (err) {
    setProcessing(false)
  }
}

export const CANDIDATE_SHORTLIST_DELETE = async (
  candidate_id,
  setProcessing,
  state,
  dispatch,
  modal,
  setRefresh,
  refresh
) => {
  try {
    await axios.delete(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_SHORTLIST +
        candidate_id +
        "/"
    )
    setProcessing(false)
    dispatch({
      type: "MODAL",
      payload: {
        showModal: modal,
      },
    })
    dispatch({
      type: "COMPANY_COUNTS",
      payload: {
        ...state.company_counts,
        shortlist: state.company_counts.shortlist - 1,
      },
    })
    setRefresh(!refresh)
  } catch (err) {
    setProcessing(false)
  }
}

export const CANDIDATE_ADD_TO_CART = async (
  company_id,
  candidate_id,
  setProcessing,
  setInCart,
  setCart,
  cart,
  state,
  dispatch
) => {
  try {
    const data = {
      company_id,
      candidate_id,
    }
    const res = await axios.post(
      process.env.GATSBY_BASE_URL + endpoints.CANDIDATE_ADD_TO_CART,
      data
    )
    if (res.status === 201) {
      if (cart && setCart) {
        setCart([...cart, { ...res.data }])
      }
      setInCart(true)
    }
    setProcessing(false)
    dispatch({
      type: "COMPANY_COUNTS",
      payload: { ...state.company_counts, cart: state.company_counts.cart + 1 },
    })
  } catch (err) {
    setProcessing(false)
  }
}

export const CANDIDATE_ADD_TO_CART_BULK = async (
  data,
  setProcessing,
  setMessage
) => {
  try {
    const res = await axios.post(
      process.env.GATSBY_BASE_URL + endpoints.CANDIDATE_ADD_TO_CART_BULK,
      data
    )
    if (res.status === 201) {
      setMessage("Candidates added to the cart successfully.")
      setTimeout(() => {
        setMessage("")
      }, 2000)
      setProcessing(false)
    }
  } catch (err) {
    setMessage("Something went wrong")
    setTimeout(() => {
      setMessage("")
    }, 2000)
    setProcessing(false)
  }
}

export const CANDIDATE_SHORTLIST_BULK = async (
  data,
  setProcessing,
  setMessage
) => {
  try {
    const res = await axios.post(
      process.env.GATSBY_BASE_URL + endpoints.CANDIDATE_SHORTLIST_BULK,
      data
    )
    if (res.status === 201) {
      setMessage("Candidates added to the shortlist successfully.")
      setTimeout(() => {
        setMessage("")
      }, 2000)
      setProcessing(false)
    }
  } catch (err) {
    setMessage("Something went wrong")
    setTimeout(() => {
      setMessage("")
    }, 2000)
    setProcessing(false)
  }
}

export const CANDIDATE_CART_DELETE = async (
  candidate_id,
  setProcessing,
  state,
  dispatch,
  modal,
  setRefresh,
  refresh
) => {
  try {
    await axios.delete(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_ADD_TO_CART +
        candidate_id +
        "/"
    )
    setProcessing(false)
    dispatch({
      type: "MODAL",
      payload: {
        showModal: modal,
      },
    })
    dispatch({
      type: "COMPANY_COUNTS",
      payload: {
        ...state.company_counts,
        cart: state.company_counts.cart - 1,
      },
    })
    setRefresh(!refresh)
  } catch (err) {
    setProcessing(false)
  }
}

export const CART_FETCH = async (dispatch, setCart) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.CANDIDATE_ADD_TO_CART
    )
    if (res.data) {
      dispatch({
        type: "CART_DATA",
        payload: res.data,
      })
      setCart(res.data)
    }
  } catch (err) {}
}

export const SHORTLIST_FETCH = async (dispatch, setShortlist, id) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.CANDIDATE_SHORTLIST + id + "/"
    )
    if (res.data && res.data.results) {
      dispatch({
        type: "SHORTLIST_DATA",
        payload: res.data.results,
      })
      setShortlist(res.data.results)
    }
  } catch (err) {}
}

export const CANDIDATE_PAID_CV_DELETE = async (
  candidate_id,
  setProcessing,
  state,
  dispatch,
  modal
) => {
  try {
    await axios.delete(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_PAID_CV +
        candidate_id +
        "/"
    )
    dispatch({
      type: "MODAL",
      payload: {
        showModal: modal,
      },
    })
    setProcessing(false)
    dispatch({
      type: "COMPANY_COUNTS",
      payload: {
        ...state.company_counts,
        paidcvs: state.company_counts.paidcvs - 1,
      },
    })
  } catch (err) {
    setProcessing(false)
  }
}

export const PAID_CV_FETCH = async (
  dispatch,
  current_page,
  setPageDetails,
  setShortlist,
  setLoading
) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_PAID_CV +
        `?page=${current_page + 1}`
    )
    if (res.data) {
      setPageDetails({ total_pages: res.data.total_pages })
      if (res.data.results) {
        dispatch({
          type: "PAID_CV_DATA",
          payload: res.data.results,
        })
        setShortlist(res.data.results)
        setLoading(false)
      }
    }
  } catch (err) {
    setLoading(false)
  }
}
