const checkIdExists = (obj, id) => {
  if (obj && obj.length > 0) {
    const isInCart = obj.filter(obj_item => obj_item.id === id)
    return isInCart.length
  }
}

export const checkIdExistsStatus = (arr, id) => {
  const index = arr.indexOf(id)
  if (index === -1) {
    return false
  }
  return true
}

export const checkIdExistsDetailPage = (obj, id) => {
  if (obj && obj.length > 0) {
    const isInCart = obj.filter(obj_item => obj_item.candidate.id === id)
    return isInCart.length
  }
}

export default checkIdExists
