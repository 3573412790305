import React, { useState, useEffect, useContext } from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"

import { GlobalAuthContext } from "../../context/authContext"

import "./layout.scss"
import "react-input-range/lib/css/index.css"
import Header from "../header/header"
import Spinner from "../spinner/spinner"
import {
  CANDIDATE_COUNTS,
  COMPANY_COUNTS,
} from "../shared-actions/countActions"
import { GET_CONFIG_DATA } from "../shared-actions/get-config-data"
import { setRedirect } from "../../utils/setRedirect"
import { CANDIDATE_PERSONAL_DATA_FETCH } from "../candidate-profile/actions/personalAction"
import { COMPANY_DETAIL_FETCH } from "../company-profile/actions/detailActions"
import { backdropAndUnscroll } from "../../utils/backdropAndUnscroll"
import Footer from "../new-home/footer"
import NewHeader from "../new-home/header"
import LandingPageFooter from "../landing-page-footer"

const Layout = ({
  location,
  children,
  background,
  title,
  noVerification,
  isAuthenticated,
  active,
  slug,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isWholePageLoading, setWholePageLoading] = useState(true)
  const { state, dispatch } = useContext(GlobalAuthContext)

  const handleHamClick = value => {
    let val = !isMenuOpen
    if (value === 0) val = false
    setMenuOpen(val)
    backdropAndUnscroll(!val)
  }
  const backdropHandler = value => {
    backdropAndUnscroll(value)
  }
  const checkForValidity = async () => {
    if (
      state?.isAuthenticated &&
      state?.user?.role === 2 &&
      !state?.profile?.avatar
    ) {
      const res = await CANDIDATE_PERSONAL_DATA_FETCH(
        state?.user?.id,
        {},
        () => {},
        () => {},
        dispatch
      )
      if (res?.data?.personal?.verified) {
        setWholePageLoading(false)
      } else {
        setRedirect(dispatch, location?.pathname + location?.search)
        navigate("/candidate/personal")
      }
    } else if (
      state?.isAuthenticated &&
      state?.user?.role === 3 &&
      !state?.profile
    ) {
      const res = await COMPANY_DETAIL_FETCH(
        dispatch,
        () => {},
        {},
        () => {}
      )
      if (res?.data?.profile?.verified) {
        setWholePageLoading(false)
      } else {
        setRedirect(dispatch, location?.pathname + location?.search)
        navigate("/company/company-detail")
      }
    }
  }
  // console.log(state)
  useEffect(() => {
    if (!state?.config) {
      GET_CONFIG_DATA(() => {}, dispatch)
    }
    if (state?.user?.company?.id && !state?.company_counts) {
      COMPANY_COUNTS(() => {}, dispatch)
    } else if (state?.user?.candidate?.id && !state?.candidate_counts) {
      CANDIDATE_COUNTS(() => {}, dispatch)
    }
    backdropHandler(true)

    if (noVerification || slug === "search-candidates") {
      if (
        state?.isAuthenticated &&
        state?.isEmailVerified &&
        !state?.hasProfile
      ) {
        if (location?.pathname) {
          checkForValidity()
        } else {
          setWholePageLoading(false)
        }
      } else if (!localStorage.token) {
        setWholePageLoading(false)
      } else if (
        localStorage.token &&
        state.isAuthenticated &&
        !state.isEmailVerified
      ) {
        if (location?.pathname === "/verify-email") {
          setWholePageLoading(false)
        } else {
          navigate("/verify-email")
        }
      } else if (localStorage.token && state.user && !state.isEmailVerified) {
        navigate("/verify-email")
      } else {
        setWholePageLoading(false)
      }
    }
  }, [state.isEmailVerified, state?.isAuthenticated, state?.hasProfile]) //eslint-disable-line react-hooks/exhaustive-deps

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (
      !state?.hasProfile &&
      state?.isAuthenticated &&
      state?.isEmailVerified
    ) {
      dispatch({
        type: "PROFILE_VERIFIED",
        payload: {
          verified: true,
          data: {
            ...state?.user,
            ...state?.user?.candidate,
            ...state?.user?.company,
          },
        },
      })
    }
  }, [state?.isEmailVerified])

  if (isWholePageLoading) {
    return <Spinner />
  }

  return (
    <div style={{ background: `${background}` }}>
      {state.isAuthenticated ? (
        <Header
          siteTitle={data.site.siteMetadata.title}
          isMenuOpen={isMenuOpen}
          handleHamClick={handleHamClick}
          active={active}
          hide={isAuthenticated || state.isAuthenticated}
        />
      ) : (
        <NewHeader />
      )}
      <main>{children}</main>
      {/* <Footer hide={isAuthenticated || state.isAuthenticated} /> */}
      {state.isAuthenticated ? "" : <Footer />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
