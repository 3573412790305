export const formatSalaryWithComma = salary => {
  if (typeof salary !== "string") {
    const value = Intl.NumberFormat("en-US").format(salary)
    if (value !== "NaN") return value
    return undefined
  } else {
    const currency = salary.substring(0, 3)
    const sub = salary.substring(3, salary.length)
    const value = Intl.NumberFormat("en-US").format(sub)
    if (value !== "NaN") return currency + " " + value
    return undefined
  }
}

export const formatSalaryWithoutComma = salary => {
  return parseInt(salary.replace(/,/g, ""))
}
