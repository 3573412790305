import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import FooterLogo from "../../../images/wdnLogo.png"
import Socials from "../../socials"

import "./footer.scss"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="wdn-new footer py-lg-116px py-72px">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <div className="footer-info pb-32px pb-lg-0">
              <div className="fi-logo pb-36px">
                <Link to="/">
                  <StaticImage
                    src="../../../images/wdnLogo.png"
                    alt="Header Logo WDN"
                    className="img-fluid"
                  />
                </Link>
              </div>
              <p className="pb-32px">
                WDN – A simple and effective way to connect Companies with
                Candidates.
              </p>
              <Socials />
            </div>
          </div>
          <div className="offset-lg-1"></div>
          <div className="col-lg-2">
            <div className="footer-menu pb-32px pb-lg-0">
              <h5>Company</h5>
              <ul>
                {/* <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li> */}
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/tac">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-menu pb-32px pb-lg-0">
              <h5>Platform</h5>
              <ul>
                {/* <li>
                  <a href="#">FAQs</a>
                </li> */}
                <li>
                  <Link to="/for-candidates">For Candidates</Link>
                </li>
                <li>
                  <Link to="/for-companies">For Companies</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-contact-info pb-32px">
              <h5>Contact Information</h5>
              <strong>WDN – Workers’ Directory Nexus</strong>
              <p>Baneshwor, Kathmandu,Nepal</p>
              <div className="fci-email">
                <span>Email:</span>
                <a href="mailto:hi@wdnexus.com ">hi@wdnexus.com </a>
              </div>
            </div>
          </div>
        </div>
        <div className="wdn-copyright pt-lg-104px pt-0">
          <p>&copy; 2024 WDN, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}
