import React, { useContext, useState, useEffect } from "react"

import { GlobalAuthContext } from "../../context/authContext"

import DetailPage from "../../components/profile-detail/detail"
import Spinner from "../../components/spinner/spinner"
import { navigate } from "gatsby-link"

const CompanyDetailPage = ({ location }) => {
  const { state } = useContext(GlobalAuthContext)

  let username = location.pathname
  username = username.split("/")
  username = username[3]

  const [wholePageLoading, setWholePageLoading] = useState(true)
  useEffect(() => {
    if (!!state?.user && !state?.isEmailVerified) {
      navigate("/verify-email")
    } else if (state?.isAuthenticated && state?.isEmailVerified) {
      setWholePageLoading(false)
    } else if (!localStorage?.token) {
      setWholePageLoading(false)
    }
  }, [state.isAuthenticated, state?.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps
  if (!wholePageLoading) {
    return (
      <DetailPage location={location} isCompany={true} username={username} />
    )
  } else {
    return <Spinner />
  }
}

export default CompanyDetailPage
