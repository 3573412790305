import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./socials.scss"
import { Link } from "gatsby"

export default function Socials() {
  return (
    <ul className="socials ">
      {/* <li>
        <Link to="https://twitter.com/WorkersNexus" target="_blank">
          <StaticImage
            src="../../images/twitter.svg"
            alt="Header Logo WDN"
            className="img-fluid"
             placeholder="blurred"
          />
        </Link>
      </li> */}
      <li>
        <Link
          to="https://www.facebook.com/workersdirectorynexus"
          target="_blank"
        >
          <StaticImage
            src="../../images/facebook.svg"
            alt="Header Logo WDN"
            className="img-fluid"
            placeholder="blurred"
          />
        </Link>
      </li>
      {/* <li>
        <Link to="https://www.instagram.com/wdnexus3/">
          <StaticImage
            src="../../images/instagram.svg"
            alt="Header Logo WDN"
            className="img-fluid"
             placeholder="blurred"
          />
        </Link>
      </li> */}
    </ul>
  )
}
